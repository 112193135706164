export const offer3bisMode = {
  MAIA_BILLING_LEVEL_1: 'MAIA_BILLING_LEVEL_1',
  MAIA_BILLING_LEVEL_2: 'MAIA_BILLING_LEVEL_2',
  MAIA_BILLING_LEVEL_3: 'MAIA_BILLING_LEVEL_3',
  DIRECT_BILLING: 'DIRECT_BILLING',
}

export const offer3bisModeLabels = {
  [offer3bisMode.DIRECT_BILLING]: 'Facture directe',
  [offer3bisMode.MAIA_BILLING_LEVEL_1]: 'Facture Maia, Devis - Facture - Recouvrement (FSC 3%)',
  [offer3bisMode.MAIA_BILLING_LEVEL_2]: 'Facture Maia, Devis - Facture - Relance devis - Recouvrement (FSC 4%)',
  [offer3bisMode.MAIA_BILLING_LEVEL_3]:
    'Facture Maia, Devis - Facture - Relance devis - Recouvrement - Secrétariat téléphonique (FSC 5%)',
}
