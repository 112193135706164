import React from 'react'
import { BooleanInput, Edit, SimpleForm } from 'react-admin'

const Offre1bisInvoiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <BooleanInput
        label="Chèque ou CESU du client reçu (en cours de traitement)"
        source="clientPaymentChequeOrCesuProcessing"
      />
      <BooleanInput source="cerfaReducedVatReceived" label="CERFA TVA réduite reçu" />
    </SimpleForm>
  </Edit>
)

export default Offre1bisInvoiceEdit
