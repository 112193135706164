import React from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FileField,
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ShowButton,
  TextInput,
  TopToolbar,
} from 'react-admin'
import ExportModalView from '../../../export/ExportModalView'
import getPaymentStatus from './GetPaymentStatusTranslation'
import { customer1bisTypeLabels } from '../../../enums/Customer1bisType'

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="offre1bis/invoices" />
  </TopToolbar>
)

const Offre1BisInvoicesFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher par nom" source="qName" alwaysOn />
    <DateInput label="De" source="qDateFrom" alwaysOn />
    <DateInput label="A" source="qDateTo" alwaysOn />
  </Filter>
)

const renderCreditNote = (creditNote) => {
  if (creditNote) {
    switch (creditNote.status) {
      case 'CREATED':
        return (
          <FileField
            label="Avoir Pro"
            source="creditNoteFile.path"
            title="number"
            target="_blank"
            record={creditNote}
          />
        )
      case 'CREATION_REQUESTED':
        return 'En attente de validation'
      case 'CREATED_BY_ACCOUNTING_DPT':
        return 'Créé par le service comptable'
      default:
        return null
    }
  }
  return null
}

const Offre1BisInvoicesList = (props) => (
  <List
    {...props}
    sort={{
      field: 'creationDate',
      order: 'DESC',
    }}
    filters={<Offre1BisInvoicesFilters />}
    actions={<ExportActions />}
  >
    <Datagrid isRowSelectable={() => false}>
      <DateField locales="fr-FR" source="creationDate" label="Date de Création" />
      <DateField locales="fr-FR" source="paymentLimitDate" label="Date Limite de Paiement" />
      <ReferenceField label="Acheteur" link="show" source="buyer.id" reference="customers1bis">
        <FunctionField render={(record) => `${record.last_name} ${record.first_name}`} />
      </ReferenceField>
      <ReferenceField label="Vendeur" link="show" source="seller.profileEntityRelationId" reference="professionals">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>
      <NumberField
        source="finalPriceTTC"
        label="Facture TTC"
        options={{
          style: 'currency',
          currency: 'EUR',
        }}
      />
      <FunctionField label="Type de client" render={(r) => customer1bisTypeLabels[r.buyer.type]} />
      <FunctionField
        label="Statut Paiement Client"
        render={(record) => getPaymentStatus(record.customerPaymentStatus)}
      />
      <FunctionField label="Statut Reversement Pro" render={(record) => getPaymentStatus(record.proPaymentStatus)} />
      <BooleanField source="cerfaReducedVatReceived" label="Cerfa TVA réduite reçu" />
      <FileField label="Facture Client" source="invoiceFile.path" title="number" target="_blank" />
      <FileField label="Facture Pro" source="proATJInvoiceFile.path" title="proATJInvoiceNumber" target="_blank" />
      <FunctionField label="Avoir Client" render={(record) => renderCreditNote(record.creditNote)} />
      <FunctionField label="Avoir Pro" render={(record) => renderCreditNote(record.proCreditNote)} />
      <ShowButton label="Encaisser" />
      <EditButton />
    </Datagrid>
  </List>
)

export default Offre1BisInvoicesList
